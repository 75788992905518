import { useLoaderData, useNavigation } from "react-router-dom";
import { fetchCartItems, getAllArts } from "../services/apiArts";
import CartCategories from "./CartCategories";
import CartItem from "./CartItem";
import Shipping from "./Shipping";
import { Fragment, useEffect, useState } from "react";
import { useCurrency } from "./CurrencyContext";
import Spinner from "./Spinner";

function ShoppingCart() {
  const { cartCount } = useCurrency();
  const arts = useLoaderData();
  const allArts = arts.data;
  const [data, setData] = useState([]);
  console.log(allArts);
  console.log(data[0]);
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    async function fetchItems() {
      try {
        const items = await fetchCartItems(userId);
        setData(items); // Update state with fetched cart items
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }

    fetchItems();
  }, [userId, cartCount]);

  return (
    <div className="container p-3">
      {isLoading ? (
        <Spinner />
      ) : data.length > 0 ? (
        <>
          <h4 className="mb-5 mt-4 fw-bold">Your Shopping Cart</h4>
          <CartItem items={data} userId={userId} />
          <div className="my-5">
            <CartCategories arts={allArts} />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center cart-zero">
          <div className="text-center">
            <i className="bi bi-cart-dash zero-cart"></i>
            <p className="h5 mt-4">NO ITEMS IN THE CART</p>
          </div>
        </div>
      )}

      <Shipping />
    </div>
  );
}

export default ShoppingCart;
export async function loader() {
  const res = await getAllArts();
  return res;
}
