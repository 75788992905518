import { useEffect, useState } from "react";
import ArtsList from "./ArtsList";
import Shipping from "./Shipping";
import ArtistQuotes from "./ArtistQuotes";
import Sort from "./Sort";
import Banner from "./Banner";
import { getPaintings } from "../services/apiArts";

function Galleries() {
  const [sorted, setSorted] = useState("latest");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    fetchData();
  }, [page, sorted]);

  async function fetchData() {
    try {
      const response = await getPaintings(page, sorted); // Pass page and sorted as parameters
      setData(response.data);
      setTotalItems(response.total);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  }

  function handleSort(sortType) {
    setSorted(sortType);
    setPage(1); // Reset page to 1 when sorting changes
  }

  function handleNext() {
    if (page < Math.ceil(totalItems / itemsPerPage)) {
      setPage(page + 1);
    }
  }

  function handlePrevious() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(page * itemsPerPage, totalItems);

  const Quote =
    "Art enables us to find ourselves and lose ourselves at the same time.";
  const Artist = "Thomas Merton";
  const BgImage = "banner";

  return (
    <div className="container">
      <div className="mt-3">
        <Banner BgImage={BgImage} />
      </div>
      <div className="quotes">
        <ArtistQuotes quote={Quote} artist={Artist} />
      </div>
      <div className="sorts">
        <Sort sorted={sorted} setSorted={handleSort} />
      </div>
      <div className="row row-cols-1 row-cols-md-4 g-4 all-galleries">
        {data.map((arts) => (
          <ArtsList arts={arts} key={arts.title} />
        ))}
      </div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="d-flex align-items-center">
          <div className="fs-6">
            Showing <b>{startItem}</b> to <b>{endItem}</b> of{" "}
            <b>{totalItems}</b> results
          </div>
        </div>
        <div className="d-flex fs-6">
          {totalItems > itemsPerPage && (
            <>
              <button
                className="me-3 btn btn-outline-secondary"
                onClick={handlePrevious}
                disabled={page === 1}
              >
                <i className="bi bi-arrow-left me-2"></i>Previous
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={handleNext}
                disabled={page === Math.ceil(totalItems / itemsPerPage)}
              >
                Next <i className="bi bi-arrow-right ms-2"></i>
              </button>
            </>
          )}
        </div>
      </div>
      <Shipping />
    </div>
  );
}

export default Galleries;
