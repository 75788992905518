import { NavLink } from "react-router-dom";

function ShowArtist({ artist }) {
  const { name, profile, photo_url: photo, quote, id } = artist;
  console.log(name);

  const photo_path = "https://backendfindarts.wd99p.com";

  return (
    <>
      <div className="row p-2">
        <div className="col-lg-3 d-flex justify-content-center align-center">
          <div className="for-img d-flex  justify-content-center align-items-center">
            <img src={`${photo_path}${photo}`} alt="artist" />
          </div>
        </div>
        <div className="col-lg-7 d-flex align-items-end">
          <div>
            <h4 className="fw-bold">{name}</h4>
            <p className="fs-6">{profile}</p>
            <p className="fs-6 fw-medium">
              <em>{quote}</em>
            </p>
          </div>
        </div>
        <div className=" col-lg-2 d-flex align-items-center justify-content-center">
          <div>
            <h4>Total Artworks</h4>
            <h6 className="text-center">5</h6>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default ShowArtist;
