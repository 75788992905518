import { useNavigate, useRouteError } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  const error = useRouteError();
  console.log(error);
  function handleHome() {
    navigate("/");
  }
  return (
    <div className="container mt-5 d-flex align-items-center justify-content-center error-container">
      <div>
        <div className="error">
          <img src="/error.png" alt="Error Page" />
        </div>
        <div>
          <h1 className="me-3 error-text ">Something went wrong! </h1>
        </div>
        <div className="text-center">
          <button onClick={handleHome} className="btn btn-secondary btn-sm ">
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default Error;
