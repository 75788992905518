import { NavLink, useNavigate } from "react-router-dom";
import { deleteArt, deleteArtist } from "../services/apiArts";
import toast from "react-hot-toast";

function ArtsProfile({ artist }) {
  const {
    title,
    artist: { name },
    photo,
    description,
    id,
    price,
    medium,
    categories,
    size,
  } = artist;
  const navigate = useNavigate();
  console.log(name);

  const photo_path = "https://backendfindarts.wd99p.com";

  async function handleDeleteArt(id) {
    try {
      await deleteArt(id);
      toast.success("Art's Deleted Successfully");
      navigate(`/admin/${categories}`);
    } catch (error) {
      toast.error("Failed to Delete");
    }
  }
  function capitalizeFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <div className="row p-2">
        <div className="col-lg-3 d-flex justify-content-center align-center">
          <div className="for-img d-flex  justify-content-center align-items-center">
            <img src={`${photo_path}${photo}`} alt="artist" />
          </div>
        </div>
        <div className="col-lg-7 d-flex align-items-end">
          <div>
            <h4 className="fw-bold mt-2">{title}</h4>
            <p className="fs-6">{description}</p>
            <p className="p-0 m-0">
              <b>Category</b> : {capitalizeFirst(categories)}
            </p>
            <p className="p-0 m-0">
              <b>Size</b> : {size}
            </p>
            <p>
              <b>Price </b> : £ {price}
            </p>
            <p className="fs-6 fw-medium">
              <b>
                <em>{name}</em>
              </b>
            </p>
          </div>
        </div>
        <div className=" col-lg-2 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center h-25">
            <NavLink to={`/edit/art/${id}`}>
              <button className="btn btn-secondary me-2">
                <i className="bi bi-pencil-square me-2"></i>Edit
              </button>
            </NavLink>
            <button
              className="btn btn-danger"
              onClick={() => handleDeleteArt(id)}
            >
              <i className="bi bi-trash3 me-2"></i>Delete
            </button>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default ArtsProfile;
