import { createContext, useContext, useState } from "react";

const CurrencyContext = createContext();

function CostProvider({ children }) {
  const [currency, setCurrency] = useState("GBP");
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState("");
  const [login, setLogins] = useState(false);
  const [delCount, setDelCount] = useState(false);
  const [cartCount, setCartCount] = useState(false);
  console.log(currency);

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
        count,
        setCount,
        users,
        setUsers,
        login,
        setLogins,
        delCount,
        setDelCount,
        cartCount,
        setCartCount,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
}

function useCurrency() {
  return useContext(CurrencyContext);
}

export { CostProvider, useCurrency };
