import React, { useRef, useState, useEffect } from "react";
import ArtistQuotes from "./ArtistQuotes";
import toast from "react-hot-toast";
import { getSingleArtist } from "../services/apiArts";
import { useLoaderData, useNavigate } from "react-router-dom";

function AdminEditArts() {
  const artistProfile = useLoaderData();
  const { id } = artistProfile;
  console.log(id);
  const navigate = useNavigate();

  const photo_path = "https://backendfindarts.wd99p.com";
  const [artistData, setArtistData] = useState({
    name: artistProfile.name || "",
    profile: artistProfile.profile || "",
    quote: artistProfile.quote || "",
    photo_url: artistProfile.photo_url
      ? `${photo_path}${artistProfile.photo_url}`
      : null,
  });

  const artistInput = useRef(null);

  useEffect(() => {
    // Update artistData with artistProfile data when it's available
    if (artistProfile) {
      setArtistData({
        name: artistProfile.name || "",
        profile: artistProfile.profile || "",
        quote: artistProfile.quote || "",
        photo_url: artistProfile.photo_url
          ? `${photo_path}${artistProfile.photo_url}`
          : null,
      });
    }
  }, [artistProfile]);

  function handleArtistChange(e) {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setArtistData({
        ...artistData,
        photo_url: file,
      });
    } else {
      setArtistData({
        ...artistData,
        photo_url: null,
      });
    }
  }

  function handleToArtist() {
    artistInput.current.click();
  }

  function handleRemoveArtist() {
    setArtistData({
      ...artistData,
      photo_url: null,
    });
    artistInput.current.value = "";
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", artistData.name);
      formData.append("profile", artistData.profile);
      formData.append("quote", artistData.quote);
      if (artistData.photo_url instanceof File) {
        formData.append("photo_url", artistData.photo_url);
      }

      const response = await fetch(
        `https://backendfindarts.wd99p.com/api/artist/info/${id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update artist");
      }

      const data = await response.json();

      console.log("Artist updated successfully:", data);
      toast.success("Artist Updated Successfully.");
      setArtistData({
        name: "",
        profile: "",
        quote: "",
        photo_url: null,
      });
    } catch (error) {
      console.error("Error updating artist:", error.message);
    }
  }

  function handleCancel() {
    navigate(-1);
  }

  const quotes =
    "Art enables us to find ourselves and lose ourselves at the same time";
  const artist = "Thomas Merton";

  return (
    <div className="container">
      <h4 className="artist-text mt-5">Modify Artist Profile</h4>
      <form onSubmit={handleSubmit}>
        <div className="row row-bot mt-4">
          <label htmlFor="name" className="col-md-6 label-of-arts">
            Artist Name
          </label>
          <div className="col-md-6 input-bot">
            <input
              type="text"
              name="name"
              className="rowsss form-control"
              value={artistData.name}
              onChange={(e) =>
                setArtistData({ ...artistData, name: e.target.value })
              }
              placeholder="e.g. Vincent"
            />
          </div>
        </div>

        <div className="row row-bot">
          <label htmlFor="profile" className="col-md-6 label-of-arts">
            Profile
          </label>
          <div className="col-md-6 input-bot">
            <textarea
              type="text"
              id="profile"
              name="profile"
              value={artistData.profile}
              onChange={(e) =>
                setArtistData({ ...artistData, profile: e.target.value })
              }
              className="rowsss form-control"
              placeholder="e.g. He began his career as an art dealer before deciding to pursue painting full-time in his late twenties."
              rows="4"
            />
          </div>
        </div>

        <div className="row row-bot">
          <label htmlFor="quotes" className="col-md-6 label-of-arts">
            Quotations
          </label>
          <div className="col-md-6 input-bot">
            <input
              type="text"
              id="quotes"
              name="quote"
              value={artistData.quote}
              onChange={(e) =>
                setArtistData({ ...artistData, quote: e.target.value })
              }
              placeholder="Creativity knows no bounds"
              className="rowsss form-control"
            />
          </div>
        </div>
        <div className="row row-bot artist-section">
          <div className="col-md-6">
            <label htmlFor="photo_url" className="label-of-arts">
              Artist Image
            </label>
          </div>
          <div className="col-md-6 artist-solo-img p-1">
            <input
              type="file"
              id="photo_url"
              name="photo_url"
              accept="image/*"
              // required={!artistData.photo_url} // Require file only if no existing photo
              style={{ display: "none" }}
              onChange={handleArtistChange}
              ref={artistInput}
            />
            <div
              className={`${
                !artistData.photo_url ? "artist-box" : ""
              } d-flex align-items-center`}
              onClick={handleToArtist}
            >
              {!artistData.photo_url && (
                <div className="d-flex  solo-artist">
                  <div className="ms-1 mt-2">
                    <i className="bi bi-upload fs-4"></i>
                  </div>
                  <div className="mx-1">
                    <p className="mt-3">Upload</p>
                  </div>
                </div>
              )}
            </div>
            {artistData.photo_url &&
              typeof artistData.photo_url === "string" && (
                <div className="artist-box">
                  <div className="artist-image-container">
                    <img
                      src={artistData.photo_url}
                      alt="Artist"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <i className="bi bi-x" onClick={handleRemoveArtist}></i>
                  </div>
                </div>
              )}
            {artistData.photo_url instanceof File && (
              <div className="artist-box d-flex justify-content-center">
                <div className="artist-image-container">
                  <img
                    src={URL.createObjectURL(artistData.photo_url)}
                    alt="Artist"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                  <i className="bi bi-x" onClick={handleRemoveArtist}></i>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="text-end button-update">
          <button type="submit" className="btn btn-secondary me-4">
            Update
          </button>
        </div>
      </form>
      <div className="button-cancel">
        <button
          type="button"
          className="btn btn-warning"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>

      <div className="quotes">
        <ArtistQuotes quote={quotes} artist={artist} />
      </div>
    </div>
  );
}

export default AdminEditArts;

export async function loader({ params }) {
  const artist = await getSingleArtist(params.artsId);
  return artist;
}
