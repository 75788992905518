import React, { useRef, useState } from "react";
import ArtistQuotes from "./ArtistQuotes";
import toast from "react-hot-toast";

function AddArtist() {
  const [artistData, setArtistData] = useState({
    name: "",
    profile: "",
    quote: "",
    photo_url: null,
  });

  const [errors, setErrors] = useState({
    name: "",
    profile: "",
    quote: "",
    photo_url: "",
  });

  const artistInput = useRef(null);

  function handleArtistChange(e) {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setArtistData({
        ...artistData,
        photo_url: file,
      });
    } else {
      setArtistData({
        ...artistData,
        photo_url: null,
      });
    }
  }

  function handleToArtist() {
    artistInput.current.click();
  }

  function handleRemoveArtist() {
    setArtistData({
      ...artistData,
      photo_url: null,
    });
    artistInput.current.value = "";
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // Validation
    let formIsValid = true;
    const newErrors = {
      name: "",
      profile: "",
      quote: "",
      photo_url: "",
    };

    if (!artistData.name.trim()) {
      newErrors.name = "Artist name is required";
      formIsValid = false;
    }

    if (!artistData.profile.trim()) {
      newErrors.profile = "Profile is required";
      formIsValid = false;
    }

    if (!artistData.quote.trim()) {
      newErrors.quote = "Quotations are required";
      formIsValid = false;
    }

    if (!artistData.photo_url) {
      newErrors.photo_url = "Artist image is required";
      formIsValid = false;
    }

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", artistData.name);
      formData.append("profile", artistData.profile);
      formData.append("quote", artistData.quote);
      formData.append("photo_url", artistData.photo_url); //

      const response = await fetch(
        "https://backendfindarts.wd99p.com/api/add/artist",
        {
          method: "POST",

          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add artist");
      }

      const data = await response.json();

      console.log("Artist added successfully:", data);
      toast.success("Artist Added Successfully.");
      setArtistData({
        name: "",
        profile: "",
        quote: "",
        photo_url: null,
      });
      setErrors({
        name: "",
        profile: "",
        quote: "",
        photo_url: "",
      });
    } catch (error) {
      toast.error("Artist addition failed due to non-unique name.");
      console.error("Error adding artist:", error.message);
    }
  }

  const quotes =
    "Art enables us to find ourselves and lose ourselves at the same time";
  const artist = "Thomas Merton";

  return (
    <div className="container ">
      <h4 className="artist-text mt-5 ">About The Artist</h4>
      <form onSubmit={handleSubmit}>
        <div className=" row row-bot mt-4">
          <label htmlFor="name" className="col-md-6 label-of-arts ">
            Artist Name
          </label>
          <div className="col-md-6 input-bot">
            <input
              type="text"
              name="name"
              className="rowsss form-control"
              value={artistData.name}
              onChange={(e) =>
                setArtistData({ ...artistData, name: e.target.value })
              }
              placeholder="e.g. Vincent"
            />

            {errors.name && <div className="text-danger">{errors.name}</div>}
          </div>
        </div>

        <div className="row row-bot">
          <label htmlFor="profile" className="col-md-6 label-of-arts">
            Profile
          </label>
          <div className="col-md-6 input-bot">
            <textarea
              type="text"
              id="profile"
              name="profile"
              value={artistData.profile}
              onChange={(e) =>
                setArtistData({ ...artistData, profile: e.target.value })
              }
              className="rowsss form-control"
              placeholder="e.g. He began his career as an art dealer before deciding to pursue painting full-time in his late twenties. His early works were characterized by dark, somber tones and depicted scenes of rural life and labor."
              rows="4"
            />
            {errors.profile && (
              <div className="text-danger">{errors.profile}</div>
            )}
          </div>
        </div>
        <div className="row  row-bot">
          <label htmlFor="quotes" className="col-md-6 label-of-arts">
            Quotations
          </label>
          <div className="col-md-6 input-bot">
            <input
              type="text"
              id="quotes"
              name="quote"
              value={artistData.quote}
              onChange={(e) =>
                setArtistData({ ...artistData, quote: e.target.value })
              }
              placeholder="Creativity knows no bounds"
              className="rowsss form-control"
            />
            {errors.quote && <div className="text-danger">{errors.quote}</div>}
          </div>
        </div>
        <div className="row row-bot artist-section ">
          <div className="col-md-6 ">
            <label htmlFor="photo_url " className="label-of-arts">
              Artist Image
            </label>
          </div>
          <div className="col-md-6 artist-solo-img">
            <input
              type="file"
              id="photo_url"
              name="photo_url"
              accept="image/*"
              required
              style={{ display: "none" }}
              onChange={handleArtistChange}
              ref={artistInput}
            />
            <div
              className={!artistData.photo_url ? "artist-box" : ""}
              onClick={handleToArtist}
            >
              {!artistData.photo_url && (
                <div className="d-flex solo-artist">
                  <div className="ms-1 mt-2">
                    <i className="bi bi-upload fs-4 "></i>
                  </div>
                  <div className="mx-1">
                    <p className="mt-3">Upload</p>
                  </div>
                </div>
              )}
            </div>
            <div className={artistData.photo_url ? "artist-box" : ""}>
              {artistData.photo_url && (
                <div className="artist-image-container">
                  <img
                    src={URL.createObjectURL(artistData.photo_url)}
                    alt="Artist"
                  />
                  <i className="bi bi-x " onClick={handleRemoveArtist}></i>
                </div>
              )}
            </div>
            {errors.photo_url && (
              <div className="text-danger">{errors.photo_url}</div>
            )}
          </div>
        </div>
        <div className="text-end">
          <button type="submit" className="btn btn-secondary">
            Add Artist
          </button>
        </div>
      </form>
      <div className="quotes">
        <ArtistQuotes quote={quotes} artist={artist} />
      </div>
    </div>
  );
}

export default AddArtist;
