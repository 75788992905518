import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home, { loader as HomeLoader } from "./components/Home";
import Galleries from "./components/Galleries";
import Login from "./components/Login";
import Contact from "./components/Contact";
import ArtsShop from "./components/ArtsShop";
import AppLayout from "./components/AppLayout";
import Error from "./components/Error";
import ArtsInfo, { loader as ArtsLoader } from "./components/ArtsInfo";
import FeaturedArts from "./components/FeaturedArts";
import Photography from "./components/Photography";
import Sculpture from "./components/Sculpture";
import Sell from "./components/Sell";
import { CostProvider } from "./components/CurrencyContext";
import ShoppingCart, { loader as cartLoader } from "./components/ShoppingCart";
import CreateAccount from "./components/CreateAccount";
import User from "./components/User";
import Dashboard from "./components/Dashboard";

import AdminHome from "./components/AdminHome";

import AddArtist from "./components/AddArtist";
import AdminPhotography from "./components/AdminPhotography";
import AdminPaintings from "./components/AdminPaintings";
import AdminScuptures from "./components/AdminScuptures";
import { Toaster } from "react-hot-toast";
import AdminEditArts, {
  loader as adminEditArtistLoader,
} from "./components/AdminEditArts";
import ListOfArtist, {
  loader as adminListArtistLoader,
} from "./components/ListOfArtist";
import AddinAdmin from "./components/AddinAdmin";
import AdminUsers from "./components/AdminUsers";
import ArtistOnly, {
  loader as artistOnlyLoader,
} from "./components/ArtistOnly";
import ArtsEdit, { loader as artsEditLoader } from "./components/ArtsEdit";
import AdminModifyArts, {
  loader as artDisplayLoader,
} from "./components/AdminModifyArts";
import ProfileUser, { loader as profileLoader } from "./components/ProfileUser";

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: HomeLoader,
      },
      {
        path: "/paintings",
        element: <Galleries />,

        errorElement: <Error />,
      },
      {
        path: "/paintings/:itemId",
        element: <ArtsInfo />,
        loader: ArtsLoader,
        errorElement: <Error />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "gallery/art",
        element: <ArtsShop />,
      },
      {
        path: "/featuredArts",
        element: <FeaturedArts />,
      },
      {
        path: "/photography",
        element: <Photography />,
      },
      {
        path: "/photography/:itemId",
        element: <ArtsInfo />,
        loader: ArtsLoader,
        errorElement: <Error />,
      },
      {
        path: "/sculptures",
        element: <Sculpture />,
      },
      {
        path: "/sculptures/:itemId",
        element: <ArtsInfo />,
        loader: ArtsLoader,
        errorElement: <Error />,
      },

      {
        path: "/cart",
        element: <ShoppingCart />,
        loader: cartLoader,
      },
      {
        path: "/profile/:usersId",
        element: <ProfileUser />,
        loader: profileLoader,
      },
    ],
  },
  {
    element: <User />,
    children: [
      {
        path: "/Login",
        element: <Login />,
      },
      {
        path: "/createaccount",
        element: <CreateAccount />,
      },
    ],
  },
  {
    element: <Dashboard />,
    children: [
      {
        element: <AdminHome />,
        path: "/dash",
      },
      {
        element: <Sell />,
        path: "/sellart",
      },
      {
        element: <AddArtist />,
        path: "/addArtist",
      },
      {
        element: <AdminPhotography />,
        path: "/admin/photography",
      },
      {
        element: <AdminPaintings />,
        path: "/admin/paintings",
      },
      {
        element: <AdminScuptures />,
        path: "/admin/sculptures",
      },
      {
        element: <AdminEditArts />,
        path: "arts/edit/:artsId",
        loader: adminEditArtistLoader,
      },
      {
        element: <ListOfArtist />,
        path: "/list/artist",
        loader: adminListArtistLoader,
      },
      {
        element: <AddinAdmin />,
        path: "/add/admin",
      },
      {
        element: <AdminUsers />,
        path: "/admin/users",
      },
      {
        element: <ArtistOnly />,
        path: "/artist/information",
        loader: artistOnlyLoader,
      },
      {
        element: <ArtsEdit />,
        path: "/arts/edit",
        loader: artsEditLoader,
      },
      {
        element: <AdminModifyArts />,
        path: "/edit/art/:artId",
        loader: artDisplayLoader,
      },
    ],
  },
]);

function App() {
  return (
    <CostProvider>
      <RouterProvider router={router} />
      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{
          margin: "8px",
        }}
        toastOptions={{
          success: {
            duration: 1000,
          },
          error: {
            durationtion: 1000,
          },
          style: {
            fontSize: "16px",
            maxWidth: "500px",
            padding: "16px 24px",
            backgroundColor: "white",
          },
        }}
      />
    </CostProvider>
  );
}
export default App;
