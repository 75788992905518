import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

function AddinAdmin() {
  const { register, formState, handleSubmit, getValues, reset } = useForm();
  const { errors } = formState;

  async function onSubmit(data) {
    try {
      const res = await fetch(
        "https://backendfindarts.wd99p.com/api/create/account",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!res.ok) {
        throw new Error("Failed to Add");
      }

      const adminData = await res.json();
      toast.success(adminData.message);
      reset();
    } catch (error) {
      console.error("Failed to add an Admin : ", error);
      toast.error("Failed to add an Admin");
    }
  }
  return (
    <div className="container">
      <h4 className="artist-text mt-5">Add New Admin</h4>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="admin-form ">
            <label className="me-5 label-admin">Username</label>
            <div>
              <input
                type="text"
                placeholder="John Doe"
                className=" form-control add-admin"
                {...register("username", {
                  required: "This field is required.",
                })}
              />
              {errors?.username && (
                <div className="mt-1 text-danger">
                  {errors.username.message}
                </div>
              )}
            </div>
          </div>
          <div className="admin-form ">
            <label className="me-5 label-admin">Email</label>
            <div>
              <input
                type="email"
                placeholder="findarts@gmail.com"
                className=" form-control add-admin"
                {...register("email", {
                  required: "This field is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email address.",
                  },
                })}
              />
              {errors?.email && (
                <div className="mt-1 text-danger">{errors.email.message}</div>
              )}
            </div>
          </div>
          <div className="admin-form ">
            <label className="me-5 label-admin">Password</label>
            <div>
              <input
                type="password"
                placeholder="********"
                className=" form-control add-admin"
                {...register("password", {
                  required: "This field is required.",
                  minLength: {
                    value: 8,
                    message: "The password must be at least 8 characters long",
                  },
                })}
              />
              {errors?.password && (
                <div className="mt-1 text-danger">
                  {errors.password.message}
                </div>
              )}
            </div>
          </div>
          <div className="admin-form ">
            <label className="me-5 label-admin">Confirm Password</label>
            <div>
              <input
                type="password"
                placeholder="********"
                className=" form-control add-admin"
                {...register("confirmPassword", {
                  required: "This field is required.",
                  validate: (value) =>
                    value === getValues().password ||
                    "Passwords needs to match",
                })}
              />
              {errors?.confirmPassword && (
                <div className="mt-1 text-danger">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-secondary me-3">Register</button>
            <button className="btn btn-warning">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddinAdmin;
