import { NavLink, useNavigate } from "react-router-dom";
import { deleteArtist } from "../services/apiArts";
import toast from "react-hot-toast";

function ArtistProfile({ artist }) {
  const { name, profile, photo_url: photo, quote, id } = artist;
  console.log(name);
  const navigate = useNavigate();
  const photo_path = "https://backendfindarts.wd99p.com";

  async function handleDeleteArtist(id) {
    try {
      await deleteArtist(id);
      toast.success("Artist Deleted Successfully");
      navigate("/list/artist");
    } catch (error) {
      toast.error("Failed to Delete");
    }
  }

  return (
    <>
      <div className="row p-2">
        <div className="col-lg-3 d-flex justify-content-center align-center">
          <div className="for-img d-flex  justify-content-center align-items-center">
            <img src={`${photo_path}${photo}`} alt="artist" />
          </div>
        </div>
        <div className="col-lg-7 d-flex align-items-end">
          <div>
            <h4 className="fw-bold">{name}</h4>
            <p className="fs-6">{profile}</p>
            <p className="fs-6 fw-medium">
              <em>{quote}</em>
            </p>
          </div>
        </div>
        <div className=" col-lg-2 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center h-25">
            <NavLink to={`/arts/edit/${id}`}>
              <button className="btn btn-secondary me-2">
                <i className="bi bi-pencil-square me-2"></i>Edit
              </button>
            </NavLink>
            <button
              className="btn btn-danger"
              onClick={() => handleDeleteArtist(id)}
            >
              <i className="bi bi-trash3 me-2"></i>Delete
            </button>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default ArtistProfile;
