import { useEffect, useState } from "react";
import { getPhotography } from "../services/apiArts";
import FeaturedArts from "./FeaturedArts";

function AdminPhotography() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    fetchData();
  }, [page]);

  async function fetchData() {
    try {
      const response = await getPhotography(page);
      setData(response.data);
      setTotalItems(response.total);
    } catch (error) {
      console.error("Faield to fetch data", error);
    }
  }

  function handleNext() {
    if (page < Math.ceil(totalItems / itemsPerPage)) {
      setPage((p) => p + 1);
    }
  }

  function handlePrevious() {
    if (page > 1) {
      setPage((p) => p - 1);
    }
  }

  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(page * itemsPerPage, totalItems);

  return (
    <div className="container">
      <h4 className="my-4 admin-category">Photography</h4>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div>
          <span>
            Showing <b>{startItem}</b> to <b>{endItem}</b> of{" "}
            <b>{totalItems}</b> results
          </span>
        </div>
        <div>
          <button
            className="me-3 btn btn-outline-secondary"
            onClick={handlePrevious}
            disabled={page === 1}
          >
            <i className="bi bi-arrow-left me-2"></i>Previous
          </button>
          <button
            className="btn btn-outline-secondary"
            onClick={handleNext}
            disabled={page === Math.ceil(totalItems / itemsPerPage)}
          >
            Next <i className="bi bi-arrow-right ms-2"></i>
          </button>
        </div>
      </div>

      <FeaturedArts arts={data} />
    </div>
  );
}

export default AdminPhotography;
